exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Icons_icon{max-width:100%;max-height:100%;font-size:4em;text-indent:100%;white-space:nowrap;overflow:hidden;display:inline-block}svg.Icons_default-icon{vertical-align:-.031em;font-size:4em}", ""]);

// exports
exports.locals = {
	"icon": "Icons_icon",
	"default-icon": "Icons_default-icon",
	"defaultIcon": "Icons_default-icon"
};